<template>
  <div id="app">
    <div id="ihc-app">
      <div class="" style="display:flex;">

        <div class="" style="flex:1;">
          <h3>Inputs</h3>
          <label for="ihc-original-balance">Original Principal Balance</label>
          <input v-model="original_balance" id="ihc-original-balance">
          <br/>
          <label for="ihc-current-balance">Current Principal Balance</label>
          <input v-model="current_balance" id="ihc-current-balance">
          <br/>

          <label for="ihc-amortization">Amortization Years</label>
          <input v-model="amortization" id="ihc-amortization">
          <br/>

          <label for="ihc-original-rate">Current Interest Rate</label>
          <input v-model="original_interest_rate" id="ihc-original-rate">
          <br/>

          <label for="ihc-target-rate">Target Interest Rate</label>
          <input v-model="target_interest_rate" id="ihc-target-rate">
          <br/>

          Estimate monthly payment: {{ original_loan_monthly | currency }}<br />
          <label for="ihc-current-monthly">Current Monthly Payment</label>
          <input v-model="custom_current_monthly" id="ihc-current-monthly">
          <br/>
        </div>

        <div style="flex:1;">
          <h3>Outputs</h3>
          <span>New Monthly Payment</span> {{ target_loan_monthly | currency }}<br/>
          <span>Extra Payment Towards Principal Repayment</span> {{ extra_payment | currency }}<br/>
          <span>Reduction in Amortization Payments</span> {{ reduction_in_payments }}<br/>
          <span>Additional Payment Saving</span> {{ additional_payment_saving | currency }} <br/>
          <span>Total Interest on Old Payment</span> {{ original_loan_interest | currency }} <br/>
          <span>Total Interest on New Payment</span> {{ total_interest_mixed_loan | currency }}<br/>
          <span>Interest Saving</span> {{ interest_saving | currency }}<br/>
        </div>
        <div style="flex:1;">
          <h3>Hybrid Outputs</h3>
          <span>New Monthly Payment</span> {{ target_loan_monthly | currency }}<br/>
          <span>Extra Payment Towards Principal Repayment</span> {{ extra_payment | currency }}<br/>
          <span>Reduction in Amortization Payments</span> {{ hybrid_reduction_in_payments }}<br/>
          <span>Additional Payment Saving</span> {{ hybrid_additional_payment_saving | currency }} <br/>
          <span>Total Interest on Old Payment</span> {{ adjusted_loan_interest | currency }} <br/>
          <span>Total Interest on New Payment</span> {{ total_interest_mixed_loan | currency }}<br/>
          <span>Interest Saving</span> {{ hybrid_interest_saving | currency }}<br/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var LoanJS = require('loanjs');

export default {
  data: function () {
    return {
      original_balance: 249764,
      current_balance: 235000,
      amortization: 25,
      original_interest_rate: 1.99,
      target_interest_rate: 2.39,
      custom_current_monthly: 1057.42,
      payment_mode: 'auto'
    }
  },
  methods: {},

  computed: {
    original_loan: function () {
      // TODO: Validate the numbers
      const loan = new LoanJS.Loan(
          this.original_balance, // amount
          this.amortization * 12,   // installments number
          this.original_interest_rate,    // interest rate
          false  // diminishing
      );

      return loan;
    },

    adjusted_original_loan: function() {

      // Get loan up till now
      let original_loan_slice = this.original_loan.installments.slice(0, this.original_payment_current_row - 1);
      let already_paid_interest = parseFloat(original_loan_slice[original_loan_slice.length -1].interestSum );

      // Get current loan
      const continued_loan = new LoanJS.Loan(
        this.current_balance,
        (this.amortization * 12) - this.original_payment_current_row,
        this.original_interest_rate,
        false
      );

      continued_loan.combinedInterestSum = parseFloat(continued_loan.interestSum) + parseFloat(already_paid_interest);

      return continued_loan;

    },

    current_monthly: function() {
      if ( this.custom_current_monthly > 0 || this.payment_mode !== 'auto' ) {
        return parseFloat(this.custom_current_monthly);
      } else {
        return this.original_loan_monthly;
      }
    },

    target_loan: function () {
      // TODO: Validate the numbers
      const loan = new LoanJS.Loan(
          this.original_balance, // amount
          this.amortization * 12,   // installments number
          this.target_interest_rate,    // interest rate
          false  // diminishing
      );
      return loan;
    },

    mixed_loan_installments: function () {
      let original_loan_slice = this.original_loan.installments.slice(0, this.original_payment_current_row - 1);
      let balance = parseFloat(original_loan_slice[original_loan_slice.length -1].remain );
      let original_interest_sum = parseFloat(original_loan_slice[original_loan_slice.length -1].interestSum );

      while( balance > 0) {
          let installment_amount = this.target_loan_monthly;
          let interest = balance * ( parseFloat( this.original_interest_rate ) / 100 / 12 );
          let capital  = installment_amount - interest;
          let remain = balance - capital;

          original_interest_sum = parseFloat( original_interest_sum ) + parseFloat(interest);

          let installment = {
            starting_balance: balance,
            capital: capital,
            installment: installment_amount,
            interest: interest,
            interestSum: original_interest_sum,
            remain: remain
          };
          balance = remain;

          original_loan_slice.push(installment);
      }

      return original_loan_slice;
    },
    target_loan_negative_row: function () {

      return this.mixed_loan_installments.findIndex(installment => installment.remain <= 0);

    },
    original_payment_current_row: function () {
      let that = this;
      return this.target_loan.installments.findIndex(installment => {
        if (parseFloat(installment.remain) <= that.current_balance) {
          return true;
        }
      });

    },
    original_loan_monthly: function () {
      return this.original_loan.installments[0].installment;
    },
    target_loan_monthly: function () {
      return this.target_loan.installments[0].installment;
    },
    extra_payment: function () {
      // TODO: Handle negative numbers
      return this.target_loan_monthly - this.current_monthly;
    },
    original_loan_capital: function () {
      return this.original_loan.capitalSum;
    },
    original_loan_interest: function () {
      return this.original_loan.interestSum;
    },
    original_loan_sum: function () {
      return this.original_loan.sum;
    },
    reduction_in_payments: function() {
      return this.original_loan.installments.length - this.target_loan_negative_row;
    },
    additional_payment_saving: function() {
      return parseFloat(this.reduction_in_payments) * parseFloat(this.current_monthly);
    },
    total_interest_mixed_loan: function() {
      return this.mixed_loan_installments[this.mixed_loan_installments.length -1].interestSum;
    },
    interest_saving: function() {
      return this.original_loan_interest - this.total_interest_mixed_loan;
    },
    adjusted_loan_interest: function () {
      return this.adjusted_original_loan.combinedInterestSum;
    }
    /*
    New outputs
    */
    ,
    hybrid_reduction_in_payments: function() {
      return ( this.original_payment_current_row + this.adjusted_original_loan.installments.length ) - this.target_loan_negative_row;
    },
    hybrid_additional_payment_saving: function() {
      return parseFloat(this.hybrid_reduction_in_payments) * parseFloat(this.current_monthly);
    },
    hybrid_interest_saving: function() {
      return this.adjusted_loan_interest - this.total_interest_mixed_loan;
    }

  },
  filters: {
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 0
      });
      return formatter.format(value);
    }
  }
}
</script>

<style>
#ihc-app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
